
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useEffect, useState } from 'react';
import Field from './components/Field/Field';
import Default from './views/Default';
import { Column, Container, Footer, Header, Inner, Heading } from './components/ui/Layout/Layout';
import logo from './media/hallby_logo.png';
import Start from './views/Start';
import Companies from './components/Companies/Companies';
import Private from './templates/Private';
import Company from './templates/Company';
import { getApiUrl } from './functions/Config';
import ProgressBar from './components/ProgressBar/ProgressBar';
import { LinkBtn } from './components/ui/Elements/Buttons';

const menuItems = [
  {
    href: "/privatperson",
    linkText: "Stötta som privatperson"
  },
  {
    href: "/foretag",
    linkText: "Stötta som företag"
  },
  {
    href: "/planen",
    linkText: "Se planen fyllas upp"
  }
]


function App() {

  const [menuOpen, toggleMenu] = useState(false);
  const [paidAreas, updatePaidAreas] = useState(false);
  const [percentage, updatePercentage] = useState(false);
  const [collectedAmount, updateCollected] = useState(0);
  const [goalAmount, updateGoal] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    // const signal = abortController.signal;

    const apiPages = getApiUrl("Progress");
    fetch(apiPages)
      .then(res => res.json())
      .then(res => {
        const fields = res.records[0].fields;
        const total = fields['Total Amount'];
        const goal = fields['Goal Amount'];
        updatePaidAreas(total / 200);
        updatePercentage((total / goal * 100).toFixed(2));
        updateGoal(goal);
        updateCollected(total);
        // setAmount(res.records)
      })
      .catch(error => console.log(error))

    return function cleanup() {
      abortController.abort();
    }
  }, [collectedAmount])

  const DefaultTemplate = ({children}) => {
    let { slug } = useParams();
    // console.log('slug: ', slug);

    return (
      <Default slug={slug}>
        {children}
      </Default>
    )
  }


  return (
    <Router>
      <HelmetProvider>
        <Helmet>
          <title>Hallby Fotboll 365</title>
        </Helmet>
        <div className={`${menuOpen ? 'menu-open': ''}`}>
          <Header logo={logo} menuItems={menuItems} scrollDistance={10} menuOpen={menuOpen} toggleMenu={toggleMenu} />
          <Switch>
            <Route exact path="/" >
              <Helmet>
                  <meta name="description" content="Ny plan för en bred förening" />
              </Helmet>
              <Start>
                <Container>
                  <Heading>
                    <h4>Nyfiken på hur det går?</h4>
                  </Heading>
                  <ProgressBar value={percentage} goal={goalAmount} collected={collectedAmount} />
                  <Heading>
                    <LinkBtn href="/planen" outline>Se planen fyllas upp</LinkBtn>
                  </Heading>
                </Container>

              </Start>
            </Route>
            <Route exact path="/info" >
              <DefaultTemplate >
                <Container>
                  Info
                </Container>
              </DefaultTemplate>

            </Route>
            <Route path="/privatperson">
              <Helmet>
                <meta name="description" content="Stötta som privatperson" />
              </Helmet>
              <Default slug="privat">

                <Private />
              </Default>
            </Route>
            <Route path="/foretag">
              <Helmet>
                <meta name="description" content="Stötta som företag" />
              </Helmet>
              <Default slug="foretag">

                <Company />
              </Default>
            </Route>
            <Route path="/planen">
                <Helmet>
                  <meta name="description" content="Se hur planen växer fram" />
                </Helmet>
              <Default slug="planen">

                <Inner y>
                  <Field paidAreas={paidAreas} percentage={percentage} goalAmount={goalAmount} collectedAmount={collectedAmount} />
                  <Companies />
                </Inner>
              </Default>
            </Route>
          </Switch>
          <Footer logo={logo}>
            <Column>
              <h3>Kontakta oss</h3>
              <p>
                Ida Jergell<br/>
                0737-068080<br />
                ida@addpeople.nu
              </p>
            </Column>
            <Column>
              <a href="https://www.hallbyfotboll.se">hallbyfotboll.se</a>
            </Column>
          </Footer>
        </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;
