import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Container } from '../ui/Layout/Layout';
import styles from './Field.module.scss';

const Square = ({fill, style})=>{
    return (
        <div style={style} className={`${styles['square' + (fill ? '--fill' : '')]}`}>
        </div>
    )
}



const Field = ({ paidAreas, percentage, goalAmount, collectedAmount}) => {

    

    const fieldSize = {
        length: 100,
        height:60
    }
    // const paidAreas = 1;
    const area = fieldSize.length * fieldSize.height;
    const areaSingles = new Array(area).fill(0);

    // const percentage = Math.round(paidAreas/area * 100);
    return (
        <div className={styles.wrapper} >
            <Container>
                <ProgressBar value={percentage} goal={goalAmount} collected={collectedAmount} />

                <div className={styles.field} 
                    style={{
                        width: fieldSize.length*7,
                        height: fieldSize.height*7
                    }}
                >

                    {areaSingles.map((val, i)=>(
                        <Square key={`field-square-${i}`} style={{
                            height: `calc(${1 + ((100 - fieldSize.height) / fieldSize.height)}% - 2px)`,
                            width: `calc(${1 + ((100 - fieldSize.length) / fieldSize.length)}% - 2px)`
                        }} fill={(i+1) <= paidAreas} children={i}/>
                    ))}
                
                </div>
            </Container>
        </div>
    );
};

export default Field;