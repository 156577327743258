import React, { useEffect, useState } from 'react';
import Companies from '../components/Companies/Companies';
import { Banner, Column, Columns, Container, Flex, Heading, Inner } from '../components/ui/Layout/Layout';
import trophy1 from '../media/trophy-basic.svg';
import { getApiUrl } from '../functions/Config';
import imgCompanies from '../media/people_companies.svg';
import imgSigns from '../media/skylt.svg';
import imgPadel from '../media/padel.svg';
import bgWhite from '../media/bg/bg-white.svg';
import bgGray from '../media/bg/bg-gray.svg';

import btnStyles from '../components/ui/Elements/Elements.module.scss';

const hallbyMail = "ida@addpeople.nu";

const MailToButton = ({subject})=>(
    <a className={`${btnStyles.button} ${btnStyles.outline}`} href={`mailto:${hallbyMail}?subject=HallbyFotboll365 – ${subject}`}>Vi vill sponsra</a>
);

const Company = () => {

    const [supportPackages, updateSupportPackages] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        // const signal = abortController.signal;

        const apiPages = getApiUrl("Packages");
        fetch(apiPages)
            .then(res => res.json())
            .then(res => {
                // console.log('records', res.records)
                updateSupportPackages(res.records);
            })
            .catch(error => console.log(error))

        return function cleanup() {
            abortController.abort();
        }
    }, [])

    return (
        <div>
            <Banner title="Vill ni stötta som företag?" imgRight={imgCompanies}>
                <p>
                Som företag har du möjlighet att vara med och bidra till att Hallby Fotboll kan investera i en nya konstgräsmatta genom att ”köpa kvadratmeter”. När du väljer något av paketen nedan så får ditt företag exponering på vår anläggning i form av er logga. Vill ni även synas digitalt på laget.se så ska ni välja paketet ”Bicycletas”. Du kan också bidra genom att boka vårt populära paket ”padel” – kanske som er nästa företagsaktivitet?
                </p>
            </Banner>
            <div className="bg-curve--gray">

            <Container>
                <Heading>
                    <h2>Kvadratmeter</h2>
                    <p>Var med och fyll planen! Välj något av våra kvadratmeterpaket och bidra till att den nya konstgräsplanen växer fram steg för steg. </p>
                </Heading>
                <Columns size="4" space>
                    {supportPackages.map((supportPackage, i)=>{
                        const fields = supportPackage.fields;

                        return(
                            <Column key={`supportPackage-${fields['Name']}`}>
                                <Flex direction="column">
                                    <img src={fields['Image'] ? fields['Image'][0].url : trophy1} alt={`Företagspaket ${fields['Name']}`} />
                                    <br/>
                                    <h3>{fields['Name']}</h3>
                                    <span>{fields['Details']}</span>
                                    <MailToButton subject={`Paket ${fields['Name']}`} />

                                </Flex>
                            </Column>
                        )
                    })}
                  
                </Columns>
            </Container>
            </div>

            <div className="bg--white">
            <Banner image={bgWhite} imgLeft={imgSigns} imgRelMobile>
                <h2>Skyltar</h2>
                <p>Skyltexponering på vår välbesökta anläggning i 3 alternativt 5 år. Skyltarna sitter väl synliga vid planen och på kanslibyggnadens balkong. </p>
                <Flex direction="column">
                    <span><b>3 år</b> 15 000 kr</span>
                    <MailToButton subject="Skyltexponering (3 år)" />
                </Flex>
                <Inner y>
                    <Flex direction="column">
                        <span><b>5 år</b> 20 000 kr</span>
                        <MailToButton subject="Skyltexponering (5 år)" />
                    </Flex>
                </Inner>
            </Banner>
            </div>
            <div className="bg-curve--gray">
                <Container>
                    <Banner image={bgGray} imgRight={imgPadel} imgRelMobile>
                    <h2>Padel</h2>
                    <p>I paketet ingår 4 timmars spel på Hallbys padelbanor med instruktör (1 timme), samt förtäring efteråt. Paketet är utformat för 12-20 personer och passar perfekt för en kickoff eller en allmän friskvårdsaktivitet med företaget. </p>
                    <span className="title--normal">20 000 kr</span>
                    <MailToButton subject="Padel" />
                </Banner>
                </Container>
            </div>

            <Companies />
        </div>
    );
};

export default Company;