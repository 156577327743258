import React, { useEffect, useState } from 'react';
import {Breadcrumbs} from '../components/ui/Layout/Layout';

const Loader = ()=>(
    <div className="loader">

    </div>
)

const Default = ({children, slug}) => {
    const [loading, setLoadingStatus] = useState(true);

    useEffect(() => {
        setLoadingStatus(true);

        setTimeout(function () {
            setLoadingStatus(false);
        }, 300)

        window.scrollTo({ top: 0 });
        // console.log('slug', slug);

    },[slug])   
    return (
        <div className={`loading${(loading ? '' : '--done')}`}>

            <Breadcrumbs back="Gå tillbaka" />
            {loading ?
                <Loader />
            :
                children
            }
        </div>
    );
};

export default Default;