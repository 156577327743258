import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconBurger, IconClose, IconLogo } from '../utils/Icons';
import styles from './Layout.module.scss';
import menuStyles from './Menu.module.scss';

const alignDefault = "center";

const Flex = ({ direction = "row", align = alignDefault, justify = alignDefault, wrap = "wrap", flex = "none", width = "100%", className = null, children }) => {
    const flexstyles = {
        display: "flex",
        flexDirection: direction,
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap,
        flex: flex,
        width: width
    }
    return (
        <div style={flexstyles} className={className}>
            {children}
        </div>
    )
}

const Box = ({ children, className = null, size, props }) => (
    <div className={`${size ? styles["box--" + size] : styles.box} ${className}`} {...props}>
        {children}
    </div>
)

const FluidBox = ({ children, className = null, props }) => (
    <div className={`${styles['padding-fluid']} ${className}`} {...props}>
        {children}
    </div>
)

const Container = ({ children }) => (
    <div className={`${styles.container}`}>
        {children}
    </div>
)

const Inner = ({ children, x, y, className }) => {
    let specific = '';

    if (x && !y) {
        specific = "--x";
    }
    if (!x && y) {
        specific = "--y";
    }

    return (
        <div className={`${className} ${styles[`inner${specific}`]}`}>
            {children}
        </div>
    )
}

const Main = ({ children }) => (
    <div className={`${styles.main}`}>
        {children}
    </div>
)

const Columns = ({ children, size = "2", space = false, stretch }) => {
    let className = !size ? `${styles.columns}` : `${styles['columns--' + size]}`;
    className += space ? ` ${styles["columns--space"]}` : "";

    return (
        <div className={`${className} ${stretch ? styles['stretch'] : ''}`}>
            {children}
        </div>
    )
}

const Column = ({ children, size = false }) => (
    <div className={!size ? `${styles.column}` : `${styles['column--' + size]}`}>
        {children}
    </div>
)


const Menu = ({ menuItems, toggleMenu }) => {
    return (
        <div className={menuStyles.menu}>
            <nav className={menuStyles.menuNav}>
                <div className={menuStyles.menuMain}>
                    {menuItems.map((item, i) => {
                        return (
                            <NavLink key={`nav-link-${i}`} onClick={toggleMenu} to={item.href}>{item.linkText}</NavLink>
                        )
                    })}
                </div>
            </nav>
        </div>
    )
}


const ToggleMenu = ({ menuOpen, onClick }) => (
    <div onClick={onClick} className={styles.iconBtn}>
        {!menuOpen ? <IconBurger /> : <IconClose onClick={onClick} />}
    </div>
)

const Header = ({ children, logo, menuItems = false, subMenuItems = false, toggleMenu, menuOpen, className, hide, scrollDistance=20 }) => {
    const [showSticky, toggleSticky] = useState(true); 
    const tolerance = { down: scrollDistance, up: (scrollDistance * -1) };
    let prev = null;

     function checkScrollDirectionIsUp(event) {
        if (event.deltaY > tolerance.down){
            prev = false;
            return false;
        }
        else if (event.deltaY < tolerance.up) {
            prev = true;
            return true;
        }
        else {
            return prev
        }
    }

    async function handleScroll(e) {

        const dirUp = await checkScrollDirectionIsUp(e);
        toggleSticky(dirUp);
        
    }

    useEffect(()=>{
        // if (!hide) return false;
        document.body.addEventListener('wheel', handleScroll);
    })

    return (
        <div className={`${styles.topHeader} ${styles['sticky'+ (showSticky ? '' : (hide ? '--hide': '--minimize')) ]} ${className ? className : ''}`}>

            <Container>

                <div className={styles.topHeaderContent}>

                    <Link to="/" className={styles.headerLogo}>{logo ? <img src={logo} alt="logo"/> : <IconLogo />}</Link>
                    {children}
                    {menuItems &&
                        <Menu menuItems={menuItems} subMenuItems={subMenuItems} menuOpen={menuOpen} toggleMenu={() => toggleMenu(!menuOpen)} />
                    }
                    <ToggleMenu onClick={() => toggleMenu(!menuOpen)} menuOpen={menuOpen} />

                </div>
            </Container>
        </div>
    )
}

const Breadcrumbs = ({ back = "Back" }) => (
    <div className={styles.breadCrumbs}>
        <Container>
            <a href="/">{back}</a>
        </Container>
    </div>

)


const Heading = ({ children }) => (
    <Flex direction="column" className={styles.heading}>
        {children}
    </Flex>
)


const Banner = ({ title, children, image, imgLeft, imgRight, imgRelMobile, className }) => (
    <>
    
    {!image ?
        <>


        <div className={`${styles.banner} ${styles.bannerSpecial}`}>
            
            <Heading>
                {(imgLeft || imgRight) &&
                    <img className={`${styles['banner-img' + (imgLeft ? '--left' : '--right')]} ${imgRelMobile ? styles['banner-img--showOnMobile'] : ''} `} src={(imgLeft || imgRight)} alt={title} />
                }
                <h1 className={styles.bannerTitle}>{title}</h1>
                {children}
            </Heading>
        </div>
        </>
    :
    <div className={`${styles.banner} ${className}`}>

        

        <div className={styles.bannerImage}>
            <img src={image} alt={title} />
            <h1 className={styles.bannerTitle}>{title}</h1>

        </div>
        
        <div className={styles.bannerContent}>
            <Container>
                
                <Heading>
                    {(imgLeft || imgRight) &&
                        <img className={`${styles['banner-img' + (imgLeft ? '--left' : '--right')]} ${imgRelMobile ? styles['banner-img--showOnMobile'] : ''} `} src={(imgLeft || imgRight)} alt={title} />

                    }
                    {!title &&
                        <h1 className={styles.bannerTitle}>{title}</h1>
                    }
                    {children}
                </Heading>
            </Container>
        </div>
    </div>
    }
    
    </>
)
// const Banner = ({ title, children, image, imgLeft, imgRight, className }) => (
//     <>
    
//     {!image ?
//         <>

//         <svg className="svg-clip">
//             <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox"><path fill="red" d="M0,0.899 q0.3,-0.539,0.6,0 q0.1,0.202,0.2,0 q0.125,-0.243,0.2,0 l0,-0.899 l-1,0"></path></clipPath>
//         </svg>
//         <div className={`${styles.banner} ${styles.bannerSpecial}`}>
            
//             <Heading>
//                 {(imgLeft || imgRight) &&
//                     <img className={`${styles['banner-img' + (imgLeft ? '--left' : '--right')]}`} src={(imgLeft || imgRight)} alt={title} />
//                 }
//                 <h1 className={styles.bannerTitle}>{title}</h1>
//                 {children}
//             </Heading>
//         </div>
//         </>
//     :
//     <div className={`${styles.banner} ${className}`}>

        

//         <div className={styles.bannerImage}>
//             <img src={image} alt={title} />
//             <h1 className={styles.bannerTitle}>{title}</h1>

//         </div>
        
//         <div className={styles.bannerContent}>
//             <Container>
                
//                 <Heading>
//                             {(imgLeft || imgRight) &&
//                                 <img className={`${styles['banner-img' + (imgLeft ? '--left' : '--right')]}`} src={(imgLeft || imgRight)} alt={title} />
//                             }
//                     {!title &&
//                         <h1 className={styles.bannerTitle}>{title}</h1>
//                     }
//                     {children}
//                 </Heading>
//             </Container>
//         </div>
//     </div>
//     }
    
//     </>
// )

const Footer = ({ children, className, id, logo = "", menuItems = false }) => (
    // console.log('menuitems: ', menuItems),

    <footer id={id} className={`bg--dark ${styles.footer} ${className}`}>
        <Container>
            <Columns size="3" space>
                <Column>
                    <Link to="/" className={styles.headerLogo}>{logo ? <img src={logo} alt="logo" /> : <IconLogo />}</Link>
                </Column>
                {children}
            </Columns>
        </Container>
    </footer>
)

export {
    Flex,
    Container,
    Inner,
    Columns,
    Column,
    Box,
    FluidBox,
    Heading,
    Banner,
    Header,
    Breadcrumbs,
    Footer,
    Main
};