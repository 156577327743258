import React from 'react';
import Persons from '../components/Persons/Persons';
import SwishQR from '../components/Swish/SwishQR';
import { Banner } from '../components/ui/Layout/Layout';
import imgPrivate from '../media/people_private.svg';
import {IconDown} from '../components/ui/utils/Icons'

const Private = () => {
    return (
        <div>
            <Banner title="Vill du stötta som privatperson?" imgLeft={imgPrivate}>
                <p>Som privatperson kan du stötta insamlingen till Hallby Fotbolls nya konstgräsmatta genom att ”köpa kvadratmeter” och samtidigt ha möjlighet att vinna fina priser.</p>

                <h3>Pris 1 m²: 200 kr</h3>

                <IconDown />
            </Banner>

            <div className="bg-curve--gray">

                <SwishQR color="#fff" />

            </div>

            <Persons />

            
        </div>
    );
};

export default Private;