import React, { useEffect, useState } from 'react';
import { IconClear } from '../../ui/utils/Icons';
import styles from "./Inputs.module.scss";


const Input = ({type="text", value, onChange, ...props}) => {
    const [val, setVal] = useState(value);
    const [focused, setFocus] = useState(false);

    function handleChange(e) {
        const inputVal = (type==="number") ? parseInt(e.target.value) : e.target.value;

        updateValue(inputVal);
    }

    function updateValue(value) {

        if(onChange){
            onChange(value);
        }
        else {
            setVal(value)
        }
    }
    
    useEffect(()=>{
        setVal(value)
    },[value])

    return (
        <div className={`${styles.inputWrapper} ${focused ? styles.inputFocus : ''} ${type === "number" ? 'input-clear-small' : ''} ${((isNaN(val) && val.length > 0) ||  Number.isInteger(val)  )? styles.inputActive : ''}`} placeholder={props.placeholder}>
            <input 
                type={type} 
                name={props.name} 
                required={props.required} 
                disabled={props.disabled} 
                className={styles.input} 
                value={val} 
                placeholder={props.placeholder} 
                min={props.min}
                max={props.max}
                onFocus={() => setFocus(true)} 
                onBlur={()=>setFocus(false)} 
                onChange={handleChange} />
            {props.clear && 
                <div className={`${styles.inputClear} ${((isNaN(val) && val.length > 0) || Number.isInteger(val)) ? '' : styles.hide}`} onClick={()=>updateValue("")}>
                    <IconClear />
                </div>
            }
        </div>
    );
};

const TextArea = ({value, updateState, ...props}) => {
    const [val, setVal] = useState(value);
    const [focused, setFocus] = useState(false);

    function handleChange(e) {
        setVal(e.target.value)

        if(updateState){
            updateState(val);
        }
    }

    useEffect(()=>{},[value, focused])

    return (
        <div className={`input-clear-small ${styles.inputWrapper} ${focused ? styles.inputFocus : ''} ${ (val.length > 0) ? styles.inputActive : ''}`} placeholder={props.placeholder}>
            <textarea className={styles.input} value={val} placeholder={props.placeholder} onFocus={() => setFocus(true)} onBlur={()=>setFocus(false)} onChange={handleChange} />
            {props.clear && 
                <div className={`${styles.inputClear} ${val.length > 0 ? '' : styles.hide}`} onClick={()=>setVal('')}>
                    <IconClear />
                </div>
            }
        </div>
    );
};

const Checkbox = ({label, children, name, checked, onChange}) => {

    return (
        <label htmlFor={name} className={styles.checkbox}>
            <input id={name} type="checkbox" checked={checked} onChange={onChange}/>
            <span>{label || children}</span>
        </label>
    )
}

export {
    Input,
    Checkbox,
    TextArea
}