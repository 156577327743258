import React from 'react';

import { Heading } from '../ui/Layout/Layout'
import { Checkbox, Input } from '../ui/Inputs'
import { Button, Tag } from '../ui/Elements/Buttons'

import styles from './Swish.module.scss';
import { useState } from 'react';
import howto1 from '../../media/swish/swish-howto-1.jpg';
import howto2 from '../../media/swish/swish-howto-2.jpg';
import { createNewAccount } from '../../functions/ApiCalls';

const QRcodes = {
    "200": "200",
    "400": "400",
    "600": "600",
    "800": "800",
    "1000": "1000",
    "1200": "1200",
    "customVal": "custom"
}

const unitPrice = 200;
const highestArea = 6;

const getValues = function () {
    let values = [];
    for (let array_area = 1; array_area <= highestArea; array_area++) {
        values.push(unitPrice * array_area)

    }
    return values;
}

const availableValues = getValues();

const SwishQR = () => {
    const [gdpr, toggleGDPR] = useState(false);
    const [nameVisible, toggleVisibility] = useState(false);
    const [userName, updateName] = useState("");
    const [teamName, updateTeamName] = useState("");
    const [selectedValue, updateValue] = useState(false);
    const [showHow, toggleHowTo] = useState(false);
    const [paymentCompleted, togglePayment] = useState(false);
    // const [values, updateValues] = useState();
    const values = availableValues;

    function handleCompleted() {
        const userObj = {
            Name: userName,
            TeamName: teamName,
            Amount: !isNaN(selectedValue) ? selectedValue : 0,
            Visible: nameVisible

        }
        // console.log('spara', userObj);

        createNewAccount(userObj).then(function(){
            setTimeout(function(){
                togglePayment(true);
            },300)
        });

    }

    return (
        <div className={styles.container}>

            { paymentCompleted ?

                <Heading>
                    <h3>Tack för ditt stöd!</h3>
                </Heading>

            :
            <>
                <Heading><h3>Fyll i dina uppgifter</h3> </Heading>
 
                <div className={`${styles.box}`}>
                    <div className={styles.selections__header}>
                        Ange det namn du vill ska synas:
                    </div>

                    <Input value={userName} placeholder="Namn" clear onChange={updateName} />
                    <br/>
         
                    <Input value={teamName} placeholder="Stötta specifikt lag" clear onChange={updateTeamName} />

                </div>

                <div className={`${styles.box} ${styles.selections} ${!userName ? styles.selectionsHide : ''}`}>
                    
                    <div className={styles.selections__header}>
                        Välj hur stor yta du vill sponsra:
                    </div>
                    <div className={styles.selections__objects}>

                        {values.map((value) => (
                            <Tag
                                key={`tag-${value}`}
                                className="tag"
                                onClick={() => updateValue(value)} 
                                active={value === selectedValue}>
                                {value / unitPrice}m²
                                <span className={styles.selections__amount}>{value} kr</span>
                            </Tag>
                        ))}

                        <Tag
                            onClick={() => updateValue('customVal')}
                            fill
                            active={'customVal' === selectedValue}>
                                Valfri summa
                        </Tag>
                    </div>

                </div>


                <div className={`${styles.box} ${styles.summary} ${!selectedValue ? styles.selectionsHide : ''}`}>
                    <div className={styles.selections__header}>
                        Sammanfattning:
                    </div>
                    <div className={styles['summary-item']}>
                        <span className={styles.title}>Namn:</span>
                        <span className={styles.filler}></span>
                        {userName}
                    </div>
                    {teamName &&
                    <div className={styles['summary-item']}>
                        <span className={styles.title}>Lag:</span>
                        <span className={styles.filler}></span>
                        {teamName}
                    </div>
                    }
                    <div className={styles['summary-item']}>
                        <span className={styles.title}>Yta:</span>
                        <span className={styles.filler}></span>
                        {!isNaN(selectedValue) ?
                            <>
                            {selectedValue / unitPrice}m²
                            </>
                            :
                            <>
                            -
                            </>
                        }
                    </div>
                    <div className={styles['summary-item']}>
                        <span className={styles.title}>Pris:</span>
                        <span className={styles.filler}></span>

                        {!isNaN(selectedValue) ?
                            <>
                            {selectedValue}kr
                            </>
                            :
                            <>
                            -
                            </>
                        }
                    </div>

                    <div className={styles.cta}>
                        
                        <Checkbox value={gdpr} onChange={() => toggleGDPR(!gdpr)}>
                            Jag godkänner att ovanstående uppgifter sparas av Hallby Fotboll. Uppgifterna sparas för enbart av redovisningssyfte. 
                        </Checkbox>

                        <Checkbox value={nameVisible} onChange={()=>toggleVisibility(!nameVisible)}>
                            Jag vill och godkänner att mitt namn visas på hemsidan.
                        </Checkbox>
                    </div>            
                </div>


            { gdpr &&
                <>
                    <div className={`${styles.box} `}>

                        <div className={styles.selections__header}>
                            Betala med Swish
                        </div>
                        <p>
                            Tänk på att betala med samma efternamn som du angett ovan.<br/><br/>
                            <a href={`/media/swish/swish-qr-hallby-${QRcodes[selectedValue]}.svg`}>
                                <img src={`/media/swish/swish-qr-hallby-${QRcodes[selectedValue]}.svg`} className={styles.qrCode} alt="Swish QR-code" />
                            </a>

                            <br/>
                            <div className={styles.showHide}>
                                <span onClick={() => toggleHowTo(!showHow)}><b>Visa hur</b> jag betalar på samma enhet jag använder nu.</span>
                                {showHow &&
                                    <ol className={styles['how-to']}>
                                        <li>Klicka på bilden för att spara QR-koden på din enhet.</li>
                                        <li>Öppna din Swish-app och klicka på <b>Swisha</b></li>
                                        <li>Tryck enligt instruktionerna på bilderna nedan:</li>
                                        <li className={styles["how-to--images"]}>
                                            <img src={howto1} alt="Swish - how to scan"/>
                                            <img src={howto2} alt="Swish - how to upload"/>
                                        </li>
                                    </ol>
                                }
                            </div>

                        </p>
                    
                    </div>
                    <Button outline fill onClick={handleCompleted}>
                        Jag har betalat in mitt stöd
                    </Button>
                </>
            }
            </>
            }
        </div>
    );
};

export default SwishQR;