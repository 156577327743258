import React from 'react';
import {Banner, Column, Columns, Container, Flex, Heading, Inner} from '../components/ui/Layout/Layout';
import bannerImage from '../media/Circle_Heading.svg';
import bannerBg from '../media/temp_bg.jpg';

import img1 from '../media/hallby-1.jpg';
import imgField from '../media/field.jpg';
import imgGoal from '../media/football_goal.jpg';
import imgPrivate from '../media/people_private.svg';
import imgCompanies from '../media/people_companies.svg';
import { LinkBtn } from '../components/ui/Elements/Buttons';
import Companies from '../components/Companies/Companies';

const imgStyles = {
    objectFit: "contain",
    height: "200px",
    marginBottom: "var(--paddingMedium)"
}

const Cta = ({img, title, link, linkText="Läs mer"}) => (
    <Column>
        <Inner y>
            <Flex direction="column" className="text-align--center">
                <img style={imgStyles} src={img} alt={title} />
                <h3>{title}</h3>
                <LinkBtn href={link} outline>{linkText}</LinkBtn>
            </Flex>
        </Inner>
    </Column>
)



const Start = ({children}) => {
    return (
        <div>
            <Banner image={bannerBg} className="banner--start">
                <img className="spin" src={bannerImage} alt=""/>
            </Banner>

            <div >
                <div className="bg-curve--green-blue">
                    <Container>
                        <Inner y>
                            <Columns size="2" center >
                                <Column>
                                <Inner>
                                    <Flex className="text-align--center">
                                        <h2>Varför nytt konstgräs?</h2>
                                        <p>
                                            Vårt upptagningsområde är stort och trycket på Hallbys planer är högt. Den nuvarande konstgräsplanen blev ett stort lyft för oss när den anlades för ett antal år sen, men slitaget har också varit högt. Därför satsar vi nu på ett nytt konstgräs för att kunna fortsätta erbjuda bra fotbollsmöjligheter oavsett väder och årstid. 
                                        </p>
                                    </Flex>
                                    </Inner>
                                </Column>
                                
                                <Column>
                                    <Flex>
                                        <img src={img1} alt="Hallby IF"/>
                                    </Flex>
                                </Column>
                            </Columns>
                            <Inner>
                                <Heading>
                            <Flex>
                                <h3>Varför stötta Hallby?</h3>
                                <p>
                                    När du stöttar Hallby Fotboll stöttar du en breddförening som är öppen för alla. Och du stöttar en anläggning som har blivit en uppskattad samlingsplats för både barn, ungdomar och vuxna. Träningar, matcher och spontanfotboll pågår på våra planer året runt. På sommaren arrangerar vi en av Sveriges största fotbollsskolor och vi har Bollkoll som är ett lag för barn med funktionsvariation i åldrarna 7-10 år. För att stödja medlemmar vars ekonomi inte för tillfället klarar de utgifter som fotbollen medför har vi inrättat Hallby-fonden, som kan bidra med exempelvis tränings- eller cupavgifter. 
                                </p>
                                <p>
                                    Varje år är vi med i ett fotbollsprojekt i Moldavien, där vi samarbetar med Hisingstorpsskolan och Andreasförsamlingen/Fjällstugan med syfte att ge barn en meningsfull fritid och framtidshopp i ett av Europas fattigaste länder. Vid sidan av själva fotbollen har vi även boulebanor och padelbanor som har ökat aktiviteten och den sociala samvaron ytterligare på vår anläggning. 
                                </p>
                            </Flex>
                                </Heading>
                            </Inner>
                        </Inner>
                    </Container>
                </div>
                    <div className="bg--blue">
                        <Inner y>
                            <Columns space>
                                <Column>
                                    <img src={imgField} alt="Gräs"/>
                                </Column>
                                <Column>
                                    <img src={imgGoal} alt="Fotboll i mål"/>
                                </Column>
                            </Columns>
                        </Inner>
                    </div>
                <div className="bg--turqoise">

                    <Container>
                        <Inner y>
                            <Columns space>
                                <Cta title="Vill du stötta som privatperson?" img={imgPrivate} link="/privatperson" linkText="Stötta som privatperson"  />
                                <Cta title="Vill du stötta som företag?" img={imgCompanies} link="/foretag" linkText="Stötta som företag"  />
                            </Columns>
                        </Inner>
                    </Container>

                </div>
            </div>
            <div className="bg-curve--gray">

                {children}
            </div>

            <Companies />
        </div>
    );
};

export default Start;