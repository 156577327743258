import React from 'react';
import { Flex } from '../ui/Layout/Layout';
import styles from './ProgressBar.module.scss';

const ProgressBar = ({ value, id, collected, goal }) => {
    return (
        <div className={styles.progressWrapper}>

            <div className={styles.progressBar}>
                <progress className={styles.progress} id={id} value={value} max="100"> {value}% </progress>
                <div style={{ left: `${value}%` }} className={styles.ball}></div>
            </div>
            <Flex justify="space-between">
                <span>Insamlat: <b>{collected} kr – {collected / 200} m²</b></span>
                <span>{value}%</span>
                <span>Mål: <b>{goal} kr – {goal / 200} m²</b></span>
            </Flex>
        </div>
    )
}
export default ProgressBar;
