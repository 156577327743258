import React from 'react';
import { Link } from 'react-router-dom';
import { IconCheck } from '../utils/Icons';
import styles from './Elements.module.scss';

const Button = ({ className, onClick, iconPath, children, disabled, fill, size, confirmation = null, confirmationMessage = null, ...props }) => {

    return (
        <button 
            style={fill ? {width: "100%"} : {}} 
            className={
                `${styles.button} ${className} 
                ${size ? styles['button--' + size] : ''}
                ${props.outline ? styles.outline : ''}
                ${props.shadow ? styles.shadow : ''}
                ${props.rounded ? styles.rounded : ''}
                ${props.circle ? styles.circle : ''}
                `}
            onClick={onClick} 
            disabled={disabled || confirmation} 
            {...props}
        >
            {children}
            {confirmationMessage &&
                <div className={`${(confirmation) ? styles['confirmation-message--show'] : styles['confirmation-message']}`}>
                    <IconCheck />
                    {confirmationMessage}
                </div>
            }
        </button>
    )
}

const Tag = ({ className, active, onClick, children, disabled, fill, size, ...props }) => {

    return (
        <button 
            style={fill ? {width: "100%"} : {}} 
            className={
                `${styles.button} ${className} 
                ${active ? '' : styles.outline}
                ${size ? styles['button--' + size] : ''}
                `}
            onClick={onClick} 
            disabled={disabled} 
            {...props}
        >
            {children}
        </button>
    )
}

const ButtonCircle = ({className, onClick, iconPath, children, type, ...props}) => {
    return (
        <Button type={type} className={`${styles.buttonCircle} ${className}`} onClick={onClick} {...props}>
            <i data-svg={iconPath}>{children}</i>
        </Button>
    )
}

const LinkBtn = ({href, children, size, inline, ...props}) => (
    <Link className={
        `${styles.button} 
        ${props.outline ? styles.outline : ''}

        ${size ? styles['button--' + size] : ''}
        ${inline ? styles['inline'] : ''}`

    } to={href}>
        {children}
    </Link>
)

export {
    Button,
    LinkBtn,
    Tag,
    ButtonCircle
}