
const Config = {
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'keyB0fY4l1f769bxT',
    base: 'appZcpsuyhTYHSopa'
}

const getApiUrl = (table = "Pages") => {

    let config = {...Config};
    config.table = table;
    return (
        config.endpointUrl + '/v0/' + config.base + '/' + config.table + '?view=Grid%20view&api_key=' + config.apiKey
    );
};

const airtableBase = () => {
    var Airtable = require('airtable');
    Airtable.configure({
        endpointUrl: Config.endpointUrl,
        apiKey: Config.apiKey
    });
    var base = Airtable.base(Config.base);

    return base;
}

export {
    Config,
    airtableBase,
    getApiUrl
};