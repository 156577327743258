import React, { useEffect, useState } from 'react';
import { getApiUrl } from '../../functions/Config';
import { Column, Columns, Container, Heading, Inner } from '../ui/Layout/Layout';
import styles from './Companies.module.scss';


const Company = ({name, url, logo}) => (

    <div className={styles.company}>
        {logo ?
        <a href={url}><img src={logo[0].url} alt={name} /></a>
        :
        <a href={url}>{name}</a>

        }
    </div>
)

const Companies = () => {
    const [Companies, setCompanies] = useState([]);

    useEffect(()=>{
        const abortController = new AbortController();
        // const signal = abortController.signal;

        const apiPages = getApiUrl("Sponsors");
        fetch(apiPages)
            .then(res => res.json())
            .then(res => {
                // console.log(res.records)
                setCompanies(res.records)
            })
            .catch(error => console.log(error))

        return function cleanup() {
            abortController.abort();
        }
    },[])

    return (
        <div>
            
            <Container>
                <Inner y>
                    <Heading size="small" title="Vi stöttar föreningen">
                        <h3 className="title--large">Vi är en del av föreningen</h3>
                    </Heading>
                    <Columns space size="6">
                        {Companies.map((company, i)=>{
                            const fields = company.fields;
                            return (
                                <Column key={'comp-' + i} >
                                    <Company 
                                        name={fields.Name} 
                                        logo={fields.Logotype} 
                                        url={fields.Website} 
                                    />
                                </Column>
                            )
                        })}
                    </Columns>
                </Inner>
            </Container>
        </div>
    );
};

export default Companies;