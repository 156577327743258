import React, { useEffect, useState } from 'react';
import { getApiUrl } from '../../functions/Config';
import { Column, Columns, Container, Flex, Heading } from '../ui/Layout/Layout';
import styles from './Persons.module.scss';

const Person = ({name}) => (
    <div className={styles.person}>
        <span className="title--normal">{name}</span>
    </div>
)

const Persons = () => {
    const [persons, setPersons] = useState([]);

    useEffect(()=>{
        const abortController = new AbortController();
        // const signal = abortController.signal;

        const apiPages = getApiUrl("Persons");
        fetch(apiPages)
            .then(res => res.json())
            .then(res => {
                // console.log(res.records)
                setPersons(res.records)
            })
            .catch(error => console.log(error))

        return function cleanup() {
            abortController.abort();
        }
    },[])

    return (
        <div>
            <Container>
                <Heading size="small" title="Vi stöttar föreningen">
                    <h3 className="title--large">Vi stöttar föreningen</h3>
                </Heading>
                <Columns size="3" space>
                    {persons.map((person, i)=>{
                        const fields = person.fields;
                        if(!fields.Visible)return false;
                        return (
                            <Column key={'pers-' + i}>
                                <Flex>
                                    <Person name={fields.Name} />
                                </Flex>
                            </Column>
                        )
                    })}
                </Columns>
            </Container>
        </div>
    );
};

export default Persons;