// import { base } from "airtable";
import { airtableBase } from "./Config";
const base = airtableBase();


const createNewAccount = async (accountDetails) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const timeOfDay = new Date().toLocaleTimeString();

    console.log('createnewaccount', accountDetails);
    accountDetails['Sum'] = ["recxlKPdWjKG2saQO"];
    accountDetails['Paid'] = false;
    accountDetails['Date'] = `${today}, ${timeOfDay}`;
    console.log('createnewaccount --> ', accountDetails);

    let myPromise = new Promise(function (myResolve, myReject) {

        base('Persons').create(accountDetails, function (err, record) {
            if (err) {
                console.error(err);
            }
            console.log(record.getId());
            myResolve(record.getId()); // when successful
            // return record;
        });

    });

    // "Consuming Code" (Must wait for a fulfilled Promise)
    return myPromise.then(
        function (value) {
            console.log('success', value); /* code if successful */
            return value
        },
        function (error) { /* code if some error */ }
    );
}

// const updateAccount = async (userId, accountDetails) => {
//     base('Persons').update(userId, accountDetails, function (err, record) {
//         if (err) {
//             console.error(err);
//             return;
//         }
//         console.log(record.getId());
//     });
// }


export {
    // updateAccount,
    createNewAccount,
    // createNewOrder,
    // createOrderLines
}